import { FC } from "react";
import { Box } from "@mui/material";
import { News } from "../../types/news";
import Carousel from "../Carousel";
import CaseStudyCard from "../CaseStudyCard";

interface Props {
  data: News[];
}

const ClientsCaseStudies: FC<Props> = ({ data }) => {
  return (
    <Box pt={3}>
      <Carousel
        slidesPerView={3}
        spaceBetween={10}
        navigation={false}
        breakpoints={{
          0: {
            slidesPerView: 1,
            slidesPerGroup: 1,
          },
          480: {
            slidesPerView: 1,
            slidesPerGroup: 1,
          },
          600: {
            slidesPerView: 1,
            slidesPerGroup: 1,
          },
          1024: {
            slidesPerView: 2,
            slidesPerGroup: 1,
          },
          1440: {
            slidesPerView: 2,
            slidesPerGroup: 1,
          },
          1920: {
            slidesPerView: 2,
            slidesPerGroup: 1,
          },
        }}
      >
        {data.map((item) => (
          <CaseStudyCard key={item.id_m_web_news} data={item} />
        ))}
      </Carousel>
    </Box>
  );
};

export default ClientsCaseStudies;
