import { FC } from "react";
import { Box } from "@mui/material";
import { useIsMobileView } from "../../hooks/useWindowSize";
import Carousel from "../Carousel";
import { CLIENT_LIST, CLIENT_LIST_DESKTOP } from "./data";
import { useStyles } from "./styles";

const HomeClientsList: FC<{}> = () => {
  const classes = useStyles();
  const inMobile = useIsMobileView();

  if (inMobile) {
    return (
      <Box className={classes.mobileContainer}>
        <Carousel
        breakpoints={{
          0: {
            slidesPerView: 2,
            slidesPerGroup: 1,
          },
          480: {
            slidesPerView: 2,
            slidesPerGroup: 1,
          },
          600: {
            slidesPerView: 2,
            slidesPerGroup: 1,
          },
        }}
        >
          {CLIENT_LIST.map(({ id, label, image }) => (
            <Box key={id} className={classes.item}>
              <img className={classes.image} src={image} alt={label} />
            </Box>
          ))}
        </Carousel>
      </Box>
    );
  }

  return (
    <Box className={classes.container}>
      <Carousel
      slidesPerView={4}
      spaceBetween={10}
      >
        {CLIENT_LIST_DESKTOP.map((sublist, idx) => (
          <Box key={idx}>
            {sublist.map((row, rowIdx) => (
              <Box key={rowIdx} className={classes.row} px={2}>
                {row.map(({ id, image, label }) => (
                  <Box key={id} className={classes.item}>
                    <img
                      key={id}
                      className={classes.image}
                      src={image}
                      alt={label}
                    />
                  </Box>
                ))}
              </Box>
            ))}
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};

export default HomeClientsList;